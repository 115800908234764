@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_cJD3gnD_vx3rCs.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_ZpC3gnD_vx3rCs.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_bZF3gnD_vx3rCs.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_c5H3gnD_vx3rCs.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
button,
input,
textarea,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  letter-spacing: 0.3px;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  color: #0d253c;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 16px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  overflow: auto;
}
body {
  line-height: 1.4;
  font-size: 16px;
  width: 100%;
  margin: 0;
  overflow: auto;
  overscroll-behavior: none;
}
html,
body {
  background: rgb(42, 43, 66);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
button {
  cursor: pointer;
}
/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.pull-down-header {
  background: linear-gradient(101deg, rgb(70, 71, 98) 0%, rgb(56, 57, 84) 100%);
  border: 1px solid rgb(86, 87, 113);
}
.pull-down-layer {
  opacity: 0;
  display: none;
}
.pull-down-header .pull-down-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pull-down-content--label {
  color: #fff;
}
.trueoneMaxWrapper {
  width: 100%;
  max-width: 620px;
  margin: 0 auto;
}
.relative {
  position: relative;
}
.grayscale {
  filter: grayscale(100%);
}
.grayscale * {
  filter: grayscale(100%) !important;
}
.grayscale-50 {
  filter: grayscale(50%);
}
.grayscale-50 * {
  filter: grayscale(50%) !important;
}
.offline_alert {
  color: #fff;
  background: #000;
  font-size: 12px;
  line-height: 20px;
  padding: 16px;
  border-radius: 4px;
  text-align: center;
  font-weight: 500;
  position: fixed;
  top: 60px;
  z-index: 10;
  width: 100%;
  transform: translateX(-50%);
  left: 50%;
  max-width: 80%;
}
.app_container {
  min-height: 100vh;
  position: relative;
  max-width: 620px;
  margin: 0 auto;
}
.app_container.offline {
  filter: grayscale(100%);
}

.trueoneSelect__menu-portal {
  width: calc(100% - 40px) !important;
  max-width: 580px;
}
.trueoneSelect__indicator.trueoneSelect__dropdown-indicator svg {
  width: 20px;
  height: 20px;
}
.overflow_h {
  overflow: hidden;
}
.swiper-wrapper .swiper-slide {
  align-items: stretch;
  height: auto;
  min-width: 100%;
  display: flex;
}

svg {
  width: 100%;
  height: 100%;
  display: block;
}

.add-to-home-screen-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #000;
  color: #fff;
  text-align: center;
}

.add-to-home-screen-banner button {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

.BASIC {
  background: #f4f4f4 !important;
}
.SILVER {
  background: linear-gradient(92.01deg, #c5c5c5 0%, #f1f0ed 100%) !important;
}
.GOLD {
  background: linear-gradient(92.01deg, #e3c485 0%, #fee7bf 100%) !important;
}
.PLATINUM {
  background: linear-gradient(92.01deg, #c6c6da 0%, #e9ebf8 100%) !important;
}
.BASIC_SIP {
  background: linear-gradient(
    92deg,
    #c5c5c5 0%,
    #f1f0ed 59.9%,
    #c5c5c5 100%
  ) !important;
  stroke-width: 1px;
  stroke: #fff;
}

.step_count__wrapper li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 14px;
}
.step_count__wrapper li span {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #fff;
  border-radius: 100%;
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
  margin-right: 8px;
  color: #fff;
}
.step_count__wrapper li p {
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}

.lowercase {
  text-transform: lowercase;
}

.investing_cardStatus {
  color: #ffa500;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  position: relative;
  padding-left: 15px;
}
.investing_cardStatus.hidden {
  display: none;
}
.investing_cardStatus:before {
  background: #ffa500;
  stroke-width: 0.4px;
  stroke: #ffa500;
  border-radius: 100%;
  filter: drop-shadow(0px 0px 6px #ffa500);
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transform: translateY(-50%);
}
.investing_cardStatus.active {
  color: #01ba61;
}
.investing_cardStatus.active:before {
  background: #01ba61;
  stroke: #01ba61;
  filter: drop-shadow(0px 0px 6px #01ba61);
}

.positive__numbers {
  color: #01ba61 !important;
}
.negative__numbers {
  color: #ff7954 !important;
}
.negative__numbers.white {
  color: #fff !important;
}
